<template>
  <v-app>
    <v-app-bar color="white" class="d-print-none" :class="{
      'd-none': $route.name === 'UserBillPrint'
    }">
      <div class="d-flex align-center ml-4">
        <router-link to="/">
          <v-img alt="Logo Groupe d'achat" class="shrink mr-2" cover
            :src="require('@/assets/logo-loco-horizontal-initiale.png')" transition="scale-transition"
            :width="logoWidth" />
        </router-link>
      </div>
      <span class="pt-2 pb-2" :class="{
        'text-caption ml-0 font-weight-bold pr-0 mr-0': $vuetify.display.smAndDown,
        'text-h5 ml-2': $vuetify.display.mdAndUp
      }">
        <router-link to="/" style="text-decoration: none; color: inherit;">
          Groupe d'achat
        </router-link>
      </span>
      <v-spacer></v-spacer> 
      <v-menu v-if="$store.state.user === null">
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props" :size="$vuetify.display.smAndDown ? 'x-small' : 'default'" variant="text" class="ml-2">
            Contact
          </v-btn>
        </template>
        <v-list>
          <v-list-item href="mailto:admin@loco-local.net">
            <template v-slot:prepend>
              <v-icon>mail</v-icon>
            </template>
            admin@loco-local.net
          </v-list-item>
          <v-list-item href="tel:581-630-0058">
            <template v-slot:prepend>
              <v-icon>phone</v-icon>
            </template>
            581-630-0058
          </v-list-item>
        </v-list>
      </v-menu>
      <BuyGroupActions :buy-group="$store.state.memberBuyGroup.buyGroup"
        v-if="$store.state.user !== null && $store.state.memberBuyGroup !== null"></BuyGroupActions>
      <v-btn variant="text" to="/connexion" v-if="$store.state.user === null"
        :size="$vuetify.display.smAndDown ? 'x-small' : 'default'" class="ml-0">
        {{ $t('app:connect') }}
      </v-btn>
      <!--      <v-btn variant="text" to="/loco-local/inscription" v-if="$store.state.user === null"-->
      <!--             :size="$vuetify.display.smAndDown ? 'x-small':'default'" class="ml-0 mr-2">-->
      <!--        {{ $t('app:register') }}-->
      <!--      </v-btn>-->
      <v-menu location="bottom left" v-if="$store.state.user !== null">
        <template v-slot:activator="{ props }">
          <v-btn icon="menu" v-bind="props" color="primary"></v-btn>
        </template>
        <v-list>
          <v-list-subheader v-if="$store.state.user !== null">
            <span class="text-h6 mr-8 font-weight-regular">
              {{ $store.state.user.firstname }}
              {{ $store.state.user.lastname }}
            </span>
          </v-list-subheader>
          <v-divider v-if="$store.getters.isAdmin" class="mt-2"></v-divider>
          <v-list-subheader v-if="$store.getters.isAdmin">
            Administration
          </v-list-subheader>
          <v-list-item :to="`/groupe/${$store.state.user.BuyGroupId}/membres`" v-if="$store.getters.isAdmin">
            <template v-slot:prepend>
              <v-icon>people</v-icon>
            </template>

            {{ $t('app:members') }}

          </v-list-item>
          <v-list-item :to="`/groupe/${$store.state.user.BuyGroupId}/commandes`" v-if="$store.getters.isAdmin">
            <template v-slot:prepend>
              <v-icon>groups_2</v-icon>
            </template>

            {{ $t('app:groupOrder') }}

          </v-list-item>
          <v-list-item :to="`/groupe/${$store.state.user.BuyGroupId}`" v-if="$store.getters.isAdmin">
            <template v-slot:prepend>
              <v-icon>settings</v-icon>
            </template>

            {{ $t('app:yourGroup') }}

          </v-list-item>
          <v-list-subheader v-if="$store.getters.isAdmin">
            {{ $t('app:products') }}
          </v-list-subheader>
          <v-list-item :to="`/groupe/${$store.state.user.BuyGroupId}/produits/admin`" v-if="$store.getters.isAdmin">
            <template v-slot:prepend>
              <v-icon>list</v-icon>
            </template>

            <v-list-item-title>
              {{ $t('app:listProducts') }}
            </v-list-item-title>

          </v-list-item>
          <v-list-item :to="`/groupe/${$store.state.user.BuyGroupId}/catalogues`" v-if="$store.getters.isAdmin">
            <template v-slot:prepend>
              <v-icon>menu_book</v-icon>
            </template>

            <v-list-item-title>
              {{ $t('app:catalogs') }}
            </v-list-item-title>

          </v-list-item>
          <!--            <v-list-item to="/produits/import" v-if="$store.getters.isAdmin">-->
          <!--              <v-list-item-action>-->
          <!--                <v-icon>import_export</v-icon>-->
          <!--              </v-list-item-action>-->
          <!--              <v-list-item-content>-->
          <!--                <v-list-item-title>-->
          <!--                  {{ $t('app:importProducts') }}-->
          <!--                </v-list-item-title>-->
          <!--              </v-list-item-content>-->
          <!--            </v-list-item>-->
          <v-divider v-if="$store.getters.isAdmin"></v-divider>
          <v-list-item @click="logout">
            <template v-slot:prepend>
              <v-icon>exit_to_app</v-icon>
            </template>
            <v-list-item-title>
              {{ $t('app:logout') }}
            </v-list-item-title>
          </v-list-item>
          <v-list-subheader v-if="$store.getters.isSuperAdmin">
            Super Admin
          </v-list-subheader>
          <v-list-item v-if="$store.getters.isSuperAdmin" to="/groupes">
            Groupes d'achats
          </v-list-item>
          <v-list-item v-if="$store.getters.isSuperAdmin" to="/catalogues/items">
            Items de catalogue
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <router-view />
    <v-footer class="bg-black font-weight-medium" height="40" :class="{
      'd-none': $route.name === 'UserBillPrint'
    }">
      <v-row justify="center" no-gutters>
        {{ new Date().getFullYear() }} —
        <strong>
          <a href="https://horizonsgaspesiens.net" style="color:white; text-decoration: none;">
            Horizons Gaspésiens
          </a>
        </strong>
      </v-row>
    </v-footer>
    <v-overlay :model-value="isLoadingFlow">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>

import I18n from "@/i18n";
import LoadingFlow from "@/LoadingFlow";
import MemberService from "@/service/MemberService";
import BuyGroupActions from "@/components/BuyGroupActions.vue";

export default {
  name: 'App',
  components: { BuyGroupActions },

  data: () => {
    const text = {
      products: "Produits du groupe",
      listProducts: "Lister",
      catalogs: "Catalogues",
      importProducts: "Importer",
      logout: "Déconnecter",
      groupOrder: "Commandes",
      yourGroup: "Votre groupe",
      members: "Membres",
      connect: "Se connecter",
      register: "S'inscrire"
    };
    I18n.i18next.addResources("fr", "app", text);
    I18n.i18next.addResources("en", "app", text);
    return {
      isLoadingFlow: false,
      loadingFlows: LoadingFlow.loadingFlows,
      logoWidth: 40
    };
  },
  mounted: async function () {
    if (this.$vuetify.display.smAndDown) {
      this.logoWidth = 25;
    }
    if (this.$store.state.user !== null) {
      const member = await MemberService.getForId(this.$store.state.user.id);
      if (member === false) {
        await this.logout();
      } else if (member.status !== this.$store.state.user.status) {
        await this.$store.dispatch('setUser', member);
      }
    }
  },
  methods: {
    logout: function () {
      this.$store.dispatch('setToken', null);
      this.$store.dispatch('setUser', null);
      this.$router.push('/connexion');
    }
  },
  watch: {
    loadingFlows: function () {
      this.isLoadingFlow = this.loadingFlows.length > 0;
    }
  }
};
</script>
<style lang="scss">
.primary-color {
  color: #4527A0
}

.vh-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bigger-hint {

  .v-text-field__details,
  .v-messages {
    min-height: 20px;
  }

  .v-messages__message {
    font-size: 16px;
    line-height: 1.5;
  }
}
</style>
